import React, { useContext, useMemo, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts';
import { TripProvider } from '../contexts';
import { DriverProvider } from '../contexts';
import { CustomerProvider } from '../contexts';
import { CustomerTripProvider } from '../contexts';
import { ContactsProvider } from '../contexts';
import { Helmet } from 'react-helmet-async';
import { ProGroupsProvider } from '../contexts';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import HorizontalLayout from '../layouts/Horizontal/';
import { NoticeProvider } from '../customers/NoticeProvider';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import ErrorPageNotFound from '../pages/error/PageNotFound';
import LogRocket from 'logrocket';

// This component will conditionally wrap its children with ContactsProvider
// only when the current path includes '/contacts'
const ContactsRouteProvider = ({ children }) => {
  const location = useLocation();
  const isContactsPage = location.pathname.includes('/contacts');

  return isContactsPage ? (
    <ContactsProvider>{children}</ContactsProvider>
  ) : (
    children
  );
};

const TripsRouteProvider = ({ children }) => {
  const location = useLocation();
  const excludePaths = ['/print-labels'];

  const shouldWrapInTripProvider = !excludePaths.includes(location.pathname);

  return shouldWrapInTripProvider ? (
    <TripProvider>{children}</TripProvider>
  ) : (
    children
  );
};

const AuthorizedRoutes = (props) => {
  const { user } = useContext(UserContext);

  const role = useMemo(() => {
    return user?.role;
  }, [user]);

  return (<Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
    <HorizontalLayout {...props}>
      <Switch>
        {authProtectedFlattenRoutes.map((route, index) => {
          let { path, roles, component, exact, children, ...rest } = route;

          return (
            !children && (
              <route.route
                key={index}
                path={path}
                roles={roles}
                exact={exact}
                component={component}
                {...rest}
              />
            )
          );
        })}
        <Redirect to="/404" />
      </Switch>
    </HorizontalLayout>
  </Route>);
};

const Routes = (props) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('ee9kgs/errands-admin');
    }
  }, []);

  const { user } = useContext(UserContext);
  const role = useMemo(() => {
    return user?.role;
  }, [user]);

  return (
    <Switch>
      <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
        <DefaultLayout {...props}>
          <Switch>
            {publicProtectedFlattenRoutes.map((route, index) => {
              return (
                !route.children && (
                  <route.route
                    key={index}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    component={route.component}
                  />
                )
              );
            })}
            <Redirect to="/404" />
          </Switch>
        </DefaultLayout>
      </Route>

      {role === 'Dispatcher' ?
        <>
          <Helmet>
            <title>Errands admin portal</title>
          </Helmet>
          <TripsRouteProvider>
            <DriverProvider>
              <CustomerProvider>
                <ContactsRouteProvider>
                  <ProGroupsProvider>
                    <AuthorizedRoutes />
                  </ProGroupsProvider>
                </ContactsRouteProvider>
              </CustomerProvider>
            </DriverProvider>
          </TripsRouteProvider>
        </>

        : role === 'Customer' ?
          <>
            <Helmet>
              <title>Errands customer portal</title>
            </Helmet>
              <NoticeProvider>
            <CustomerTripProvider>
              <AuthorizedRoutes />
            </CustomerTripProvider>
              </NoticeProvider>
          </>
          : <AuthorizedRoutes />}
    </Switch>
  );
};

export default Routes;