// NoticeProvider.jsx
import React, { useState, useEffect, createContext, useContext } from 'react';
import Swal from 'sweetalert2';
import api2 from '../helpers/api2';

const NoticeContext = createContext();

export const useNotice = () => useContext(NoticeContext);

export const NoticeProvider = ({ children }) => {
  const [notice, setNotice] = useState('');

  const getNotice = async () => {
    try {
      const res = await api2.get('settings');
      const customerMessageItem = res.data.find(item => item.label === "customerMessage");
       console.log('customerMessageItem', customerMessageItem)
      if (customerMessageItem && customerMessageItem.message && customerMessageItem.value === 'true') {
        const message = customerMessageItem.message;
        setNotice(message);
        
        // Show alert
        Swal.fire({
          title: 'Important Notice:',
          text: message,
          icon: 'info',
          dangerMode: true,
          closeOnClickOutside: false,
        });
      }
    } catch (err) {
      console.error('Error fetching customer notice:', err);
    }
  };

  useEffect(() => {
    getNotice();
  }, []);

  return (
    <NoticeContext.Provider value={{ notice, getNotice }}>
      {children}
    </NoticeContext.Provider>
  );
};