import React, { useState, useEffect, createContext, useContext } from 'react'
import { UserContext } from './UserContext'
import api2 from '../helpers/api2'

// Create a singleton to hold contacts data outside of component lifecycle
const contactsCache = {
  data: [],
  isLoaded: false
};

export const ContactsContext = createContext()

export default function ContactsProvider(props) {
    const [contacts, setContacts] = useState(contactsCache.data)
    const { socket } = useContext(UserContext)
    const [loading, setLoading] = useState(!contactsCache.isLoaded)

    function getContacts() {
        // If we've already fetched contacts, use the cached data
        if (contactsCache.isLoaded) {
            setContacts(contactsCache.data)
            setLoading(false)
            return
        }

        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + '/contacts?sort[firstName]=asc&limit=400000', { credentials: 'include' })
            .then(res => res.json())
            .then(res => {
                // Update both local state and our global cache
                setContacts(res)
                contactsCache.data = res
                contactsCache.isLoaded = true
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    // Fetch contacts when the component mounts
    useEffect(() => {
        getContacts()
    }, [])

    const C = (c, contactsObject) => {
        const temp = [...c]
        temp[temp.findIndex(e => e.id === contactsObject.id)] = contactsObject
        return temp
    }

    useEffect(() => {
        function update(contactsObject) {
            setContacts(c => {
                const newContacts = C(c, contactsObject)
                contactsCache.data = newContacts // Update our cache
                return newContacts
            })
        }
        
        function newC(contactsObject) {
            console.log('new customer in socket', contactsObject)
            setContacts(c => {
                const newContacts = [contactsObject, ...c]
                contactsCache.data = newContacts // Update our cache
                return newContacts
            })
        }
        
        function deleted(id) {
            console.log('new deleted in socket', id)
            setContacts(c => {
                const newContacts = c.filter(e => e.id !== id)
                contactsCache.data = newContacts // Update our cache
                return newContacts
            })
        }

        socket.current.on('customer:new', newC)
        socket.current.on('customer:update', update)
        socket.current.on('customer:deleted', deleted)

        return () => {
            socket.current.off('customer:new', newC)
            socket.current.off('customer:update', update)
            socket.current.off('customer:deleted', deleted)
        }
    }, [])

    return (
        <ContactsContext.Provider
            value={{
                contacts,
                setContacts,
                loading,
                setLoading,
                getContacts,
            }}>
            {props.children}
        </ContactsContext.Provider>
    )
}